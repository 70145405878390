import { createTheme } from '@material-ui/core/styles';

export const defaultThemeOption = {
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#2869E5',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    background: {
      paper: '#EBF1F4',
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiSvgIcon: {
      // Name of the rule
      root: {
        // Some CSS
        height: 24,
        width: 24,
      },
    },
    MuiInputBase: {
      input: {
        height: 19,
      },
    },
  },
};

export const muiTheme = options => {
  return createTheme(options || {});
};
