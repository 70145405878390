const path = require('path-browserify');

const calculatedPath = () => {
  if (process.env.TARGET_ENV === 'development') {
    return 'http://localhost:8080/';
  }
  if (process.env.TARGET_ENV === 'staging') {
    return 'https://s3.eu-west-3.amazonaws.com/widget.intramuros.org/staging/';
  }

  return 'https://widget.intramuros.org/';
};

module.exports = {
  root: path.resolve(__dirname, '../'),
  outputPath: path.resolve(__dirname, '../', 'build'),
  entryPath: path.resolve(__dirname, '../', 'src/index.jsx'),
  entryAgendaPath: path.resolve(__dirname, '../', 'src/index-agenda.jsx'),
  entryActualitePath: path.resolve(__dirname, '../', 'src/index-actualite.jsx'),
  entryDiapoPath: path.resolve(__dirname, '../', 'src/index-diaporama.jsx'),
  entrySondagePath: path.resolve(__dirname, '../', 'src/index-sondage.jsx'),
  entrySuggestionPath: path.resolve(__dirname, '../', 'src/index-suggestion.jsx'),
  entryToolsPath: path.resolve(__dirname, '../', 'src/index-tools.jsx'),
  templatePath: path.resolve(__dirname, '../', 'src/template.html'),
  templateDiapoPath: path.resolve(__dirname, '../', 'src/template-diapo.html'),
  templateAgendaPath: path.resolve(
    __dirname,
    '../',
    'src/template-agenda.html',
  ),
  templateActualitePath: path.resolve(
    __dirname,
    '../',
    'src/template-actualite.html',
  ),
  templateSondagePath: path.resolve(__dirname, '../', 'src/template-sondage.html'),
  templateSuggestionPath: path.resolve(__dirname, '../', 'src/template-suggestion.html'),
  templateToolsPath: path.resolve(__dirname, '../', 'src/template-tools.html'),
  intramurosCssFile: path.resolve(__dirname, '../', 'src/intramuros.css'),
  imagesFolder: 'images',
  fontsFolder: 'fonts',
  cssFolder: 'css',
  jsFolder: 'js',
  widgetVersion: '3.3.1',
  publicPath: calculatedPath(),
};
