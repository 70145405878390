import { orderBy, forEach, size, uniqBy, filter } from 'lodash';

export const calculateNewsFilters = (newsList) => {
    /*
    Les filtres: Tous, Collectivités, Associations, Commerces, Ecoles, Gendarmerie, Mediatheque
    */
    let result = [];
    forEach(newsList, (item) => {
        if (item) {
            // On affiche le filtre de l'acteur si c'est une actu acteur local ou si c'est une actu liée à un acteur: Dans les deux cas item.orgnaizer_type est renseigné.
            if (item.organizer_type) {
                if (item.organizer_type === 'ASSO')
                    result.push({ "id": "04", "value": item.organizer_type, "label": item.organizer_type_label || "Associations" })
                else if (item.organizer_type === 'COMMERCE')
                    result.push({ "id": "05", "value": item.organizer_type, "label": item.organizer_type_label || "Commerces" })
                else if (item.organizer_type === 'MEDIATHEQUE')
                    result.push({ "id": "06", "value": item.organizer_type, "label": item.organizer_type_label || "Culture" })
                else if (item.organizer_type === 'SCHOOL')
                    result.push({ "id": "07", "value": item.organizer_type, "label": item.organizer_type_label || "Scolarité - Jeunesse" })
                else if (item.organizer_type === 'POLICE')
                    result.push({ "id": "08", "value": item.organizer_type, "label": item.organizer_type_label || "Gendarmerie" })
                else if (item.organizer_type === 'PREFECTURE')
                    result.push({ "id": "09", "value": item.organizer_type, "label": item.organizer_type_label || "Préfecture" })
                else if (item.organizer_type === 'SANTE')
                    result.push({ "id": "10", "value": item.organizer_type, "label": item.organizer_type_label || "Santé" })
            }

            // On exclue explicitement les actus locale (target) car elles ont aussi le city renseigné
            if (item.city && !item.target) {
                result.push({ "id": "01", "value": "CITY", "label": "Commune" })
            } else if (item.agglo && !item.target) {
                result.push({ "id": "02", "value": "INTERCO", "label": "Intercommunalité" })
            } else if (item.dpt && !item.target) {
                result.push({ "id": "03", "value": "DPT", "label": "Département" })
            }
        }
    })

    result = uniqBy(result, "id")

    if (size(result) > 1) {
        result.push({ "id": "00", "value": "ALL", "label": "Toutes" })
        return orderBy(result, ["value"], ["asc"])
    }
    return []
}

export const getNewsListFiltered = (newsList, selectedFilter) => {

    if (!selectedFilter || selectedFilter === "ALL") {
        return newsList
    }

    return filter(newsList, (obj) => {
        if (selectedFilter === "CITY") {
            if (obj && !obj.target && obj.city) {
                return true
            }
        } else if (selectedFilter === "INTERCO") {
            if (obj && !obj.target && obj.agglo) {
                return true
            }
        }
        else if (selectedFilter === "DPT") {
            if (obj && !obj.target && obj.dpt) {
                return true
            }
        }
        else if (obj && obj.organizer_type && obj.organizer_type === selectedFilter) {
            return true
        }
        return false
    })

};