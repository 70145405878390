import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 190,
  },
}));

export default function SelectorNewsType({ options, selectOption }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    typeNews: 'ALL',
  });

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      [name]: event.target.value,
    });
    selectOption(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel
        ref={inputLabel}
        key={inputLabel}
        htmlFor="outlined-typeNews-native-simple"
        style={{
          fontSize: 16,
        }}
      >
        {"Type de publications"}
      </InputLabel>
      <Select
        native
        value={state.typeNews}
        onChange={handleChange('typeNews')}
        labelWidth={labelWidth}
        inputProps={{
          name: 'typeNews',
          id: 'outlined-typeNews-native-simple',
        }}
        style={{
          fontSize: 16,
        }}
      >
        {/* <option value="0">Toutes</option> */}
        {options.map((option) => (
          <option value={option.value} key={option.id}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

SelectorNewsType.propTypes = {
  options: PropTypes.objectOf(PropTypes.object()).isRequired,
  selectOption: PropTypes.objectOf(PropTypes.object()).isRequired,
};
