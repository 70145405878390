import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { withStyles } from '@material-ui/core/styles';
import { CardMedia, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash'
import DialogBox from './DialogBox';
import GalleryFullScreen from "./GalleryFullScreen";

const ITEM_HEIGHT = 280;
const imageMaxHeight =
  parseInt(window.imageMaxHeight, 10) > 0
    ? parseInt(window.imageMaxHeight, 10)
    : ITEM_HEIGHT;

const styles = () => ({
  media: {
    objectFit: 'contain',
    height: `${imageMaxHeight}px !important`,
    cursor: 'pointer',
    maxHeight: `${imageMaxHeight}px !important`, // Important car le carousel est dans une div en position absolue
  },
  thumbnail: {
    height: 30,
    width: 30,
    objectFit: 'cover',
    cursor: 'pointer',
    border: '1px solid #337ab7',
  },
});

class Gallery extends Component {
  state = {
    open: false,
    content: null,
    video: false,
  };

  generateCardMedia = () => {
    const { content, video } = this.state;
    if (content) {
      if (video) {
        return (
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ width: '100%' }}
          >
            <iframe
              title="video"
              width="560"
              height="315"
              src={content}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder={0}
            />
          </Grid>
        );
      }
      return <CardMedia component="img" image={content} />;
    }

    return null;
  };

  handleClickOpen = (url, isVideo) => {
    this.setState({
      open: true,
      content: url,
      video: isVideo,
    });
  };

  onHandleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { medias, classes } = this.props;

    const settings = {
      dots: true,
      dotsClass: 'slick-dots slick-thumb',
      arrows: false,
      infinite: false,
      lazyLoad: true,
      speed: 250,
      slidesToShow: 1,
      slidesToScroll: 1,
      customPaging: (i) => {
        if (medias && medias[i]) {
          if (medias[i].url) {
            return (
              <CardMedia
                key={medias[i].url}
                component="img"
                className={classes.thumbnail}
                image={medias[i].url}
              />
            );
          }
          if (medias[i].id) {
            return (
              <CardMedia
                key={medias[i].id}
                component="img"
                className={classes.thumbnail}
                image={
                  'https://img.youtube.com/vi/' + medias[i].id + '/default.jpg'
                }
              />
            );
          }
        }
        return null;
      },
    };

    const { open } = this.state;

    if (isEmpty(medias)) {
      return null;
    }

    if (medias.length === 1 && medias[0].url) {
      return (
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <CardMedia
            component="img"
            className={classes.media}
            image={medias[0].url}
            onClick={() => this.handleClickOpen(medias[0].url, false)}
          />
          <DialogBox
            open={open}
            content={this.generateCardMedia()}
            handleClose={this.onHandleClose}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          marginTop: 10,
          width: '100%',
          height: '100%',
          position: 'relative',
          minHeight: imageMaxHeight + 90, // Important vue qu'on est en position absolue en dessous
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
          }}
        >
          <Slider {...settings} key={medias.toString()}>
            {medias.map((media) => {
              if (media && media.url) {
                return (
                  <CardMedia
                    key={media.url}
                    component="img"
                    className={classes.media}
                    image={media.url}
                    onClick={() => this.handleClickOpen(media.url, false)}
                  />
                );
              }
              if (media && media.id) {
                return (
                  <iframe
                    title="video"
                    width="auto"
                    height={imageMaxHeight}
                    src={'https://www.youtube.com/embed/' + media.id}
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    frameBorder={0}
                  />
                );
              }
              return null;
            })}
          </Slider>
        </div>
        <GalleryFullScreen open={open} handleClose={this.onHandleClose} medias={medias} />
        {/* <DialogBox
          open={open}
          content={this.generateCardMedia()}
          handleClose={this.onHandleClose}
        /> */}
        <style>{`
          .slick-thumb li img {
            width: 100%;
            height: 100%;
            filter: grayscale(100%);
          }
          .slick-thumb li.slick-active img {
            filter: grayscale(0%);
          }
        `}</style>
        {/* CSS pour les carousels: slick */}
        <style>{`
          .slick-list,
          .slick-slider,
          .slick-track {
            position: relative;
            display: block;
          }
          .slick-loading .slick-slide,
          .slick-loading .slick-track {
            visibility: hidden;
          }
          .slick-slider {
            box-sizing: border-box;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-touch-callout: none;
            -khtml-user-select: none;
            -ms-touch-action: pan-y;
            touch-action: pan-y;
            -webkit-tap-highlight-color: transparent;
          }
          .slick-list {
            overflow: hidden;
            margin: 0;
            padding: 0;
          }
          .slick-list:focus {
            outline: 0;
          }
          .slick-list.dragging {
            cursor: pointer;
            cursor: hand;
          }
          .slick-slider .slick-list,
          .slick-slider .slick-track {
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }
          .slick-track {
            top: 0;
            left: 0;
          }
          .slick-track:after,
          .slick-track:before {
            display: table;
            content: '';
          }
          .slick-track:after {
            clear: both;
          }
          .slick-slide {
            display: none;
            float: left;
            height: 100%;
            min-height: 1px;
          }
          [dir='rtl'] .slick-slide {
            float: right;
          }
          .slick-slide img {
            display: block;
          }
          .slick-slide.slick-loading img {
            display: none;
          }
          .slick-slide.dragging img {
            pointer-events: none;
          }
          .slick-initialized .slick-slide {
            display: block;
          }
          .slick-vertical .slick-slide {
            display: block;
            height: auto;
            border: 1px solid transparent;
          }
          .slick-arrow.slick-hidden {
            display: none;
          } /*# sourceMappingURL=slick.min.css.map */
        `}</style>
        {/* CSS pour les carousels: slick-theme */}
        <style>{`
          @charset 'UTF-8';
          .slick-next,
          .slick-prev {
            position: absolute;
            display: block;
            padding: 0;
          }
          .slick-dots li button:before,
          .slick-next:before,
          .slick-prev:before {
            font-family: slick;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
          .slick-loading .slick-list {
            background: url(ajax-loader.gif) center center no-repeat #fff;
          }
          @font-face {
            font-family: slick;
            font-weight: 400;
            font-style: normal;
            src: url(fonts/slick.eot);
            src: url(fonts/slick.eot?#iefix) format('embedded-opentype'),
              url(fonts/slick.woff) format('woff'),
              url(fonts/slick.ttf) format('truetype'),
              url(fonts/slick.svg#slick) format('svg');
          }
          .slick-next,
          .slick-prev {
            font-size: 0;
            line-height: 0;
            top: 50%;
            width: 20px;
            height: 20px;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            cursor: pointer;
            color: transparent;
            border: none;
            outline: 0;
            background: 0 0;
          }
          .slick-next:focus,
          .slick-next:hover,
          .slick-prev:focus,
          .slick-prev:hover {
            color: transparent;
            outline: 0;
            background: 0 0;
          }
          .slick-next:focus:before,
          .slick-next:hover:before,
          .slick-prev:focus:before,
          .slick-prev:hover:before {
            opacity: 1;
          }
          .slick-next.slick-disabled:before,
          .slick-prev.slick-disabled:before {
            opacity: 0.25;
          }
          .slick-next:before,
          .slick-prev:before {
            font-size: 20px;
            line-height: 1;
            opacity: 0.75;
            color: #fff;
          }
          .slick-prev {
            left: -25px;
          }
          [dir='rtl'] .slick-prev {
            right: -25px;
            left: auto;
          }
          .slick-prev:before {
            content: 'â†';
          }
          .slick-next:before,
          [dir='rtl'] .slick-prev:before {
            content: 'â†’';
          }
          .slick-next {
            right: -25px;
          }
          [dir='rtl'] .slick-next {
            right: auto;
            left: -25px;
          }
          [dir='rtl'] .slick-next:before {
            content: 'â†';
          }
          .slick-dotted.slick-slider {
            margin-bottom: 30px;
          }
          .slick-dots {
            bottom: -25px;
            width: 100%;
            margin: 0;
            list-style: none;
            text-align: center;
            margin-top: 5px;
            position: initial;
            display: block;
            padding: 0;
          }
          .slick-dots li {
            position: relative;
            display: inline-block;
            width: 28px;
            height: 28px;
            margin: 4px;
            padding: 0;
            cursor: pointer;
          }
          .slick-dots li button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: 0;
            background: 0 0;
          }
          .slick-dots li button:focus,
          .slick-dots li button:hover {
            outline: 0;
          }
          .slick-dots li button:focus:before,
          .slick-dots li button:hover:before {
            opacity: 1;
          }
          .slick-dots li button:before {
            font-size: 6px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            content: 'â€¢';
            text-align: center;
            opacity: 0.25;
            color: #000;
          }
          .slick-dots li.slick-active button:before {
            opacity: 0.75;
            color: #000;
          } /*# sourceMappingURL=slick-theme.min.css.map */
        `}</style>
      </div>
    );
  }
}

Gallery.propTypes = {
  classes: PropTypes.objectOf(PropTypes.object()).isRequired,
  medias: PropTypes.objectOf(PropTypes.object()).isRequired,
};

export default withStyles(styles)(Gallery);
