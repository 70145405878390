import React, { Component } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{ width: '100%' }}
        spacing={0}
      >
        <div style={{ marginTop: 25 }}>
          <CircularProgress style={{ width: 50, height: 50 }} />
        </div>
      </Grid>
    );
  }
}

Loader.propTypes = {};

export default Loader;
